import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  addMacadam, addMacadams, removeMacadam, clearMacadams, changeMacadam,
  updateLastPath, addPathToLastMacadam, removeLastPath
} from './Actions';
import { showNotice } from '../../../../src/notice/Actions.js';
import { showConfirm } from '../../../../src/confirm/Actions.js';
import { showMessage } from '../../../../src/message/Actions.js';
import DataField from '../../../../src/dataField/DataField.js';
import { selectTruck } from '../../../../src/truckSelect/Actions.js';
import ContractSelect from '../../../../src/contractSelect/ContractSelect.js';
import TruckSelect from '../../../../src/truckSelect/TruckSelect.js';
import ConstructionSiteSelect from '../../../../src/constructionSiteSelect/ConstructionSiteSelect.js';
import {
  fetch, integerValue, floatValue, Socket, timer, toETRSTM35FIN, paddedNumber,
  toRadians, toDegrees, toWGS84, stateValueParser, fetchSensorData, calculateDistance,
  calculateRoadDistance, getRoadData, getRoadCoordinates
} from '../utils.js';
import { MapView, RoadwaySelect, LaneSelect, DirectionSelect, AccuracyFixer } from './Components';
import ChangeMacadamView from './Components';
import { kalmanFilter, resetKalmanFilter } from './KalmanFilter';
import config from '../../settings/config.js';
import './Macadam.css';

const REPTAIL = config.reptail;

const View = props => {
  if (props.view === 0) {
    return (
      <div>
        <BasicInfo changeState={props.changeState} height={props.height}
          multiplier={props.multiplier}
          variety={props.variety} pit={props.pit}
          pits={props.pits} goAddingPit={props.goAddingPit}
          lastHeight={props.lastHeight} lastMultiplier={props.lastMultiplier}
          lastVariety={props.lastVariety} lastPit={props.lastPit}
          sensorDevices={props.sensorDevices}
          selectedSensorDevice={props.selectedSensorDevice}
          store={props.store} />
      </div>
    );
  }
  else if (props.view === 1) {
    return (
      <div>
        <MassInput changeState={props.changeState}
          roadway={props.roadway}
          lane={props.lane}
          direction={props.direction}
          roadPart={props.locationRoadPart}
          startRoadPart={props.startRoadPart}
          startRoadDistance={props.startRoadDistance}
          latitude={props.latitude}
          longitude={props.longitude}
          lastLatitude={props.lastLatitude}
          lastLongitude={props.lastLongitude}
          road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'}
          newMacadam={props.newMacadam}
          useLocation={props.useLocation}
          mass={props.mass}
          attentions={props.attentions}
          dailyMass={props.dailyMass}
          dailyCount={props.dailyCount} lastPart={props.lastPart}
          lastPole={props.lastPole}
          macadams={props.macadams}
          autoGPS={props.autoGPS}
          endMacadam={props.endMacadam}
          creatingMacadam={props.creatingMacadam}
          disableSubmit={props.disableSubmit}
          lastDirection={props.lastDirection}
          lastRoadway={props.lastRoadway}
          lastLane={props.lastLane}
          newPath={props.newPath}
          disableNewPath={props.disableNewPath}
          store={props.store} />
      </div>
    );
  }
  else if (props.view === 2) {
    return (
      <div>
        <TrackingInfo changeState={props.changeState} macadams={props.macadams}
          target={props.selectedConstructionSite} dailyMass={props.dailyMass}
          wholeMass={props.wholeMass}
          loading={props.loading}
          removeLastPath={props.removeLastPath}
          removeMacadam={props.confirmRemoveMacadam}
          removeLocalMacadam={props.confirmRemoveLocalMacadam}
          roadNumber={props.locationRoadNumber} roadPart={props.locationRoadPart}
          roadPole={props.locationRoadDistance} accuracy={props.accuracy}
          time={props.locationTime} changeMacadam={props.changeMacadam}
          latitude={props.latitude} longitude={props.longitude} />
      </div>
    );
  }
  else if (props.view === 4) {
    return (
      <div>
        <SensorInfo latestAngles={props.latestAngles} latestWidths={props.latestWidths}
          latestLeftPotHeights={props.latestLeftPotHeights} latestRightPotHeights={props.latestRightPotHeights}
          lastSensorTime={props.lastSensorTime} />
      </div>
    );
  }
  else {
    return (
      <div>
        <MapView macadams={props.macadams} yourLatitude={props.latitude} yourLongitude={props.longitude}
          roadNumber={props.roadNumber} roadPart={props.roadPart}
          roadPole={props.roadPole} accuracy={props.accuracy}
          showMessage={props.showMessage} site={props.selectedConstructionSite}
          mapPaths={props.mapPaths} mapZoom={props.mapZoom}
          mapPosition={props.mapPosition} organizationId={props.organizationId}
          loading={props.loading} REPTAIL={REPTAIL} />
      </div>
    );
  }
};

const BasicInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Perustiedot</h4>
      </legend>
      <div className='row'>
        <div className='column'>
          <img className='label-icon' alt='' src='icons/contract.gif' />
          <ContractSelect required store={props.store} />
        </div>
        <div className='column'>
          <img className='label-icon' alt='' src='icons/site.gif' />
          <ConstructionSiteSelect required store={props.store} />
        </div>
      </div>
      <div className='row'>
        <div className="column">
          <img className='label-icon' alt='' src='icons/height.gif' />
          <label htmlFor='height'>Korkeus (mm)</label>
          {props.lastHeight != null ? <div>{'Edellinen: ' + props.lastHeight + ' mm'}<br /></div> : null}
          <input id='height' type='tel'
            onChange={props.changeState.bind(this, 'height', 'float', 0.0)} value={props.height} required />
        </div>
        <div className="column">
          <img className='label-icon' alt='' src='icons/multiplier.gif' />
          <label htmlFor='multiplier'>Kerroin</label>
          {props.lastMultiplier != null ? <div>{'Edellinen: ' + props.lastMultiplier}<br /></div> : null}
          <input id='multiplier' type='tel'
            onChange={props.changeState.bind(this, 'multiplier', 'float', 0.0)} value={props.multiplier} required />
        </div>
      </div>
      <div className='row'>
        <div className="column">
          <img className='label-icon' alt='' src='icons/variety.gif' />
          <label htmlFor='variety'>Lajike</label>
          {props.lastVariety ? <div>{'Edellinen: ' + props.lastVariety}<br /></div> : null}
          <select id='variety'
            onChange={props.changeState.bind(this, 'variety', 'string', '')}
            value={props.variety}>
            <option value=''>Valitse lajike</option>
            <option value='0-16'>0-16</option>
            <option value='0-32'>0-32</option>
            <option value='0-45'>0-45</option>
            <option value='0-55'>0-55</option>
            <option value='0-63'>0-63</option>
          </select>
        </div>
        <div className="column">
          <img className='label-icon' alt='' src='icons/pit.gif' />
          <label htmlFor='pit'>Monttu</label>
          {props.lastPit ? <div>{'Edellinen: ' + props.lastPit}<br /></div> : null}
          <select id='pit' className='float-left'
            onChange={props.changeState.bind(this, 'pit', 'string', '')}
            value={props.pit}>
            <option value=''>Valitse monttu</option>
            {
              props.pits.map((pit, index) => (
                <option key={index} value={pit}>
                  {pit}
                </option>
              ))
            }
          </select>
          <button className='button-outline' onClick={props.goAddingPit}>
            Uusi monttu
          </button>
        </div>
      </div>
      <img className='label-icon' alt='' src='icons/device.gif' />
      <label>
        Sensorilaite
        <select onChange={props.changeState.bind(this, 'selectedSensorDevice', 'string', true)}
          value={props.selectedSensorDevice} >
          <option value={''}>Valitse laite</option>
          {props.sensorDevices.map(device => (
            <option key={device} value={device}>
              {device}
            </option>
          ))
          }
        </select>
      </label>
    </fieldset>
  );
};

const SensorInfo = props => {
  let currentAngle = '';
  let currentWidth = '';
  let currentLeftPotHeight = '';
  let currentRightPotHeight = '';
  let time = '-';

  console.log(props.lastSensorTime)

  if (props.lastSensorTime != null) {

    time = props.lastSensorTime.getDate() + '.' + (props.lastSensorTime.getMonth() + 1) +
      '.' + props.lastSensorTime.getFullYear() + ' ' + paddedNumber(props.lastSensorTime.getHours()) +
      ':' + paddedNumber(props.lastSensorTime.getMinutes());
  }

  if (props.latestAngles.length !== 0) {
    currentAngle = props.latestAngles[props.latestAngles.length - 1]['_value'];
  }

  if (props.latestWidths.length !== 0) {
    currentWidth = props.latestWidths[props.latestWidths.length - 1]['_value'];
  }

  if (props.latestLeftPotHeights.length !== 0) {
    currentLeftPotHeight = props.latestLeftPotHeights[props.latestLeftPotHeights.length - 1]['_value'];
  }

  if (props.latestRightPotHeights.length !== 0) {
    currentRightPotHeight = props.latestRightPotHeights[props.latestRightPotHeights.length - 1]['_value'];
  }

  return (
    <fieldset>
      <legend>
        <h4>Sensoritiedot</h4>
      </legend>
      <p>
        Päivitetty: {time}
      </p>
      <div className='row'>
        <div className="column">
          <img className='label-icon' alt='' src='icons/angle.gif' />
          <label htmlFor='currentAngle'>Kulma</label>
          <input id='currentAngle' type='tel'
            value={currentAngle} readOnly />
        </div>
        <div className="column">
          <img className='label-icon' alt='' src='icons/width.gif' />
          <label htmlFor='currentWidth'>Leveys</label>
          <input id='currentWidth' type='tel'
            value={currentWidth} readOnly />
        </div>
        <div className="column">
          <img className='label-icon' alt='' src='icons/left_height.gif' />
          <label htmlFor='currentLeftPotHeight'>Vasen korkeus</label>
          <input id='currentLeftPotHeight' type='tel'
            value={currentLeftPotHeight} readOnly />
        </div>
        <div className="column">
          <img className='label-icon' alt='' src='icons/right_height.gif' />
          <label htmlFor='currentRightPotHeight'>Oikea korkeus</label>
          <input id='currentRightPotHeight' type='tel'
            value={currentRightPotHeight} readOnly />
        </div>
      </div>
    </fieldset>
  );
};

const CurrentData = props => {
  return (
    <div>
      Päivän käytetty massa: <strong>{Math.round(props.dailyMass * 100) / 100 || '-'}</strong> tonnia
      <br />
      Kohteen käytetty massa: <strong>{Math.round(props.wholeMass * 100) / 100 || '-'}</strong> tonnia
    </div>
  );
}

const MassInput = props => {
  return (
    <fieldset>
      <legend>
        <h4>Levitystiedot</h4>
      </legend>
      <div>
        <img className='label-icon' alt='' src='icons/location.gif' />
        <div className='row borders'>
          <div className='column'>
            <label htmlFor='roadPart'>Latitude</label>
            Edellinen: {props.lastLatitude || '-'}
            <input id='roadPart' type='tel'
              value={props.latitude || '-'}
              readOnly={true} />
          </div>
          <div className='column'>
            <label htmlFor='pole'>Longitude</label>
            Edellinen: {props.lastLongitude || '-'}
            <input id='pole' type='tel'
              value={props.longitude || '-'}
              readOnly={true} />
          </div>
        </div>
        {props.startRoadPart ?
          <div className='row borders'>
            <div className='column'>
              <label htmlFor='road'>Tie</label>
              <input id='road' type='text' value={props.road || ''} readOnly />
            </div>
            <div className='column'>
              <label htmlFor='roadPart'>Tieosa</label>
              Edellinen: {props.lastPart || '-'}
              <input id='roadPart' type='tel'
                value={props.startRoadPart}
                onChange={props.changeState.bind(this, 'road_part', 'integer', 0)}
                readOnly={props.autoGPS}
                required />
            </div>
            <div className='column'>
              <label htmlFor='pole'>Paalu</label>
              Edellinen: {props.lastPole || '-'}
              <input id='pole' type='tel'
                value={props.startRoadDistance}
                onChange={props.changeState.bind(this, 'road_distance', 'integer', 0)}
                readOnly={props.autoGPS}
                required />
              <label className='checkbox'>
                Automaattinen GPS
                <input type='checkbox'
                  onChange={props.changeState.bind(this, 'autoGPS', 'boolean', true)}
                  checked={props.autoGPS} />
              </label>
            </div>
          </div>
          :
          null
        }
        <div className='row borders'>
          <div className='column'>
            <img className='label-icon' alt='' src='icons/direction.gif' />
            <DirectionSelect onChange={props.changeState} direction={props.direction}
              last={props.lastDirection} />
          </div>
          <div className='column'>
            <img className='label-icon' alt='' src='icons/roadway.gif' />
            <RoadwaySelect onChange={props.changeState} roadway={props.roadway}
              last={props.lastRoadway} />
          </div>
          <div className='column'>
            <img className='label-icon' alt='' src='icons/lane.gif' />
            <LaneSelect onChange={props.changeState} lane={props.lane}
              last={props.lastLane} />
          </div>
        </div>
        <div className='row borders'>
          <div className='column'>
            <img className='label-icon' alt='' src='icons/truck.gif' />
            <label htmlFor='truck'>Rekka</label>
            Päivän kuormat: {props.dailyCount || '-'} kpl
            <TruckSelect required store={props.store} />
          </div>
          <div className='column'>
            <img className='label-icon' alt='' src='icons/mass.gif' />
            <label htmlFor='mass'>Kuorma (Tonnit) </label>
            Päivän: {props.dailyMass || '-'} tonnia
            <input id='mass' type='tel'
              value={props.mass}
              onChange={props.changeState.bind(this, 'truckMass', 'float', 0.0)} required />
          </div>
        </div>
        <img className='label-icon' alt='' src='icons/notice.gif' />
        <label htmlFor='attentions'>Huomiot</label>
        <input id='attentions' type='text' value={props.attentions || ''}
          onChange={props.changeState.bind(this, 'attentions', 'string', '')} />
        <div className='center row'>
          <div className='column'>
            {props.disableSubmit ? <div className='loader float-left' /> :
              (props.creatingMacadam ?
                <button id='end-button'
                  onClick={props.endMacadam}>
                  Lopeta kuorma
                </button>
                :
                <button id='start-button'
                  onClick={props.newMacadam}>
                  Aloita kuorma
                </button>)
            }
          </div>
          <div className='column'>
            {props.disableNewPath ?
              <div className='loader' />
              :
              <button onClick={props.newPath} className='button-outline'>
                Jatka edellistä kuormaa
              </button>
            }
          </div>
        </div>
      </div>
    </fieldset>
  );
}

const TrackingInfo = props => {
  return (
    <fieldset>
      <legend>
        <h4>Seurantatiedot</h4>
      </legend>
      <FiveLastMacadams macadams={props.macadams} target={props.target}
        loading={props.loading} removeMacadam={props.removeMacadam}
        removeLocalMacadam={props.removeLocalMacadam} changeMacadam={props.changeMacadam}
        removeLastPath={props.removeLastPath} />
      <div className='row'>
        <div className='column'>
          <CurrentLocation roadNumber={props.roadNumber} roadPart={props.roadPart}
            roadPole={props.roadPole} loading={props.loading}
            accuracy={props.accuracy} time={props.time}
            latitude={props.latitude} longitude={props.longitude} />
        </div>
        <div className='column'>
          <CurrentData dailyMass={props.dailyMass} wholeMass={props.wholeMass} />
        </div>
      </div>
    </fieldset>
  );
};

const FiveLastMacadams = props => {
  if (props.target == null) return null;
  if (props.macadams.size === 0) return <p>Ei yhtään lisäystä</p>;
  if (props.loading) return <div className="loader"></div>;

  return (
    <div>
      <h4>5 viimeisintä mursketta</h4>
      {props.macadams.splice(0, props.macadams.size - 5).map(macadam => {
        const time = new Date(macadam.get('date'));
        const clock = paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes());
        const date = time.getDate() + '.' + (time.getMonth() + 1) + '.' + time.getFullYear();

        let header = [];

        let truck = '-'

        if (macadam.get('truck').get('register_number')) {
          truck = macadam.get('truck').get('register_number');
        }

        let mass = '-'

        if (macadam.get('truck_mass')) {
          mass = macadam.get('truck_mass');
        }

        header.push(
          <span key={macadam.get('id')}>
            {clock}
            <span className='spade' />
            <span className='small'>{date}</span>
            <span className='spade' />
            <img className='header-icon' alt='' src='icons/truck.gif' />
            {':' + truck}
            <span className='spade' />
            <img className='header-icon' alt='' src='icons/mass.gif' />
            {':' + mass}
          </span>
        );

        if (macadam.get('not_saved')) {
          header.push(<span key={macadam.get('id') + '-not_saved'}>
            {macadam.get('not_saved') ? ' (Ei palvelimella)' : ''}
          </span>);
        }

        let data = {
          'Huomiot': macadam.get('attentions') || '-',
          'Korkeus (mm)': macadam.get('height') || '-',
          'Kerroin': macadam.get('multiplier') || '-',
          'Lajike': macadam.get('variety') || '-',
          'Monttu': macadam.get('pit') || '-'
        };

        return (
          <div className='datafield' key={macadam.get('id')}>
            <DataField header={header} data={data} class={macadam.get('not_saved') ? 'yellow' : null}>
              <br />
              {macadam.get('paths').map((path, index) => (
                <p key={index}>
                  {path.get('start_part') != null ?
                    (
                      macadam.get('paths').size > 1 ?
                        <span>
                          <strong>{index + 1 + '. '}</strong>
                          {path.get('start_part') + ' / ' + path.get('start_distance') +
                            ' - ' + (path.get('end_part') ? (path.get('end_part') + ' / ' + path.get('end_distance')) : '')}
                        </span>
                        :
                        <span>
                          {(path.get('start_part') != null ? (path.get('start_part') + ' / ' + path.get('start_distance')) : '') +
                            ' - ' + (path.get('end_part') ? (path.get('end_part') + ' / ' + path.get('end_distance')) : '')}
                        </span>
                    )
                    :
                    ''
                  }
                  <strong>Ajorata:</strong> {' ' + path.get('roadway') + ' '}
                  <strong>Suunta:</strong> {' ' + path.get('direction') + ' '}
                  <strong>Kaista:</strong> {' ' + path.get('lane') + ' '}
                  <strong>Pituus:</strong> {' ' + (path.get('length') != null ? (path.get('length') + ' m ') : '-')}
                </p>
              ))
              }
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeMacadam.bind(this, macadam)}>
                    Muokkaa
                  </button>
                </div>
                {macadam.get('paths').size > 1 ?
                  <div className='column'>
                    <button className='button-outline' onClick={props.removeLastPath.bind(this, macadam)}>
                      Poista viimeisin levitys
                    </button>
                  </div>
                  : null
                }
                <div className='column'>
                  <button className='delete-button' onClick={macadam.get('not_saved') ?
                    props.removeLocalMacadam.bind(this, macadam.get('id')) :
                    props.removeMacadam.bind(this, macadam.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};

const CurrentLocation = props => {
  let accuracyColor;
  let accuracy = props.accuracy;

  if (accuracy > 20) accuracyColor = 'red';
  else if (accuracy > 10) accuracyColor = 'yellow';
  else if (accuracyColor != null) accuracyColor = 'green';

  if (accuracy >= 10000) accuracy = '-';

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={3}>
            Nykyinen sijainti
          </th>
        </tr>
      </thead>
      <tbody>
        {props.roadPart ?
          <tr>
            <td>
              {'Tie: '}
              {props.roadNumber || '-'}
            </td>
            <td>
              {'Tieosa: '}
              {props.roadPart || '-'}
            </td>
            <td>
              {'Paalu: '}
              {props.roadPole || '-'}
            </td>
          </tr>
          :
          null
        }
        <tr>
          <td colSpan={2}>
            {'Latitude: '}
            {props.latitude || '-'}
          </td>
          <td>
            {'Longitude: '}
            {props.longitude || '-'}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            {'Päivitetty: '}
            {props.time || '-'}
          </td>
          <td>
            {'Tarkkuus: '}
            <span id={'location-accuracy-' + accuracyColor}>{accuracy || '-'} </span>m
          </td>
        </tr>
      </tbody>
    </table>
  );
}


export const MacadamNew = (properties) => {
  const props = properties;

  let watchID = null;
  let sensorTimer = null;
  let SENSOR_TIME_AGO = 3;
  let socket = useRef(null);
  let removingMacadam;
  let removingPathByMacadam;

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [roadway, setRoadway] = useState(0);
  const [lane, setLane] = useState(1);
  const [direction, setDirection] = useState(1);
  const [height, setHeight] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const [variety, setVariety] = useState('');
  const [pit, setPit] = useState('');
  const [road_part, setRoad_part] = useState(0);
  const [road_distance, setRoad_distance] = useState(0);
  const [truckMass, setTruckMass] = useState(0);
  const [attentions, setAttentions] = useState("");
  const [dailyCount, setDailyCount] = useState(0);
  const [dailyMass, setDailyMass] = useState(0);
  const [viewState, setViewState] = useState(0);
  const [autoGPS, setAutoGPS] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [traveledDistance, setTraveledDistance] = useState(null);
  const [pits, setPits] = useState([]);
  const [paths, setPaths] = useState({});
  const [mapPaths, setMapPaths] = useState([]);
  const [latestAngles, setLatestAngles] = useState([]);
  const [latestWidths, setLatestWidths] = useState([]);
  const [latestLeftPotHeights, setLatestLeftPotHeights] = useState([]);
  const [latestRightPotHeights, setLatestRightPotHeights] = useState([]);
  const [sensorDevices, setSensorDevices] = useState([]);
  const [selectedSensorDevice, setSelectedSensorDevice] = useState(null);
  const [usingSensorGPS, setUsingSensorGPS] = useState(false);
  const [width, setWidth] = useState();
  const [creatingMacadam, setCreatingMacadam] = useState();
  const [creatingPath, setCreatingPath] = useState();
  const [lastPath, setLastPath] = useState();
  const [accuracy, setAccuracy] = useState();
  const [locationTime, setLocationTime] = useState();
  const [sensor, setSensor] = useState();
  const [deepnessSensor, setDeepnessSensor] = useState();
  const [locationErrorState, setLocationErrorState] = useState();
  const [locationRoadNumber, setLocationRoadNumber] = useState();
  const [locationRoadPart, setLocationRoadPart] = useState();
  const [locationRoadDistance, setLocationRoadDistance] = useState();
  const [showAccuracyFixer, setShowAccuracyFixer] = useState(null);
  const [callback, setCallback] = useState(null);
  const [loadingMacadams, setLoadingMacadams] = useState();
  const [changingMacadam, setChangingMacadam] = useState();
  const [changingMass, setChangingMass] = useState();
  const [wholeMass, setWholeMass] = useState();
  const [accuracySubmit, setAccuracySubmit] = useState();
  const [lastPart, setLastPart] = useState();
  const [lastPole, setLastPole] = useState();
  const [lastHeight, setLastHeight] = useState();
  const [lastVariety, setLastVariety] = useState();
  const [lastPit, setLastPit] = useState();
  const [lastDirection, setLastDirection] = useState();
  const [lastRoadway, setLastRoadway] = useState();
  const [lastMultiplier, setLastMultiplier] = useState();
  const [lastLane, setLastLane] = useState();
  const [mapZoom, setMapZoom] = useState();
  const [mapPosition, setMapPosition] = useState();
  const [disableSubmit, setDisableSubmit] = useState();
  const [disableNewPath, setDisableNewPath] = useState();
  const [sensors, setSensors] = useState();
  const [lastSensorTime, setLastSensorTime] = useState();
  const [lastLatitude, setLastLatitude] = useState();
  const [lastLongitude, setLastLongitude] = useState();
  const [lastSensorLatitude, setLastSensorLatitude] = useState();
  const [lastSensorLongitude, setLastSensorLongitude] = useState();

  useEffect(() => {
    if (localStorage['login'] == null) {
      return;
    }

    if (socket.current == null && typeof (WebSocket) !== 'undefined') {
      socket.current = Socket('/data/macadam');
    }

    if (typeof (Storage) !== "undefined") {
      setRoadway(integerValue(localStorage.roadway, 0));
      setLane(integerValue(localStorage.lane, 1));
      setDirection(integerValue(localStorage.direction, 1));
      setHeight(floatValue(localStorage.height, 0));
      setWidth(floatValue(localStorage.width, 0));
      setMultiplier(floatValue(localStorage.multiplier, 0));
      setVariety(localStorage.variety ? localStorage.variety : '');
      setPit(localStorage.pit ? localStorage.pit : '');
      setRoad_part(integerValue(localStorage.road_part, 0));
      setRoad_distance(integerValue(localStorage.road_distance, 0));
      setTruckMass(integerValue(localStorage.truckMass, 0));
      setAttentions(localStorage.attentions ? localStorage.attentions : '');
      setAutoGPS(localStorage.autoGPS === 'false' ? false : true);
      setCreatingMacadam(localStorage.creatingMacadam ? JSON.parse(localStorage.creatingMacadam) : null);
      setCreatingPath(localStorage.creatingPath ? JSON.parse(localStorage.creatingPath) : null);
      setPits(localStorage.pits ? JSON.parse(localStorage.pits) : []);
    }

    if (REPTAIL) {
      getSensorDevices();
      toggleSensors();
    }

    watchID = navigator.geolocation.watchPosition(setLocation, locationError,
      { enableHighAccuracy: true });

    return () => {
      navigator.geolocation.clearWatch(watchID);
      clearInterval(sensorTimer);
      if (socket.current != null) socket.current.close();
    };
  }, [])

  useEffect(() => {
    if (creatingPath != null) {
      setTraveledDistance(Math.round(creatingPath.distance * 10) / 10);
    } else {
      setTraveledDistance(null);
    }
  }, [creatingPath])

  useEffect(() => {
    if (lastPath != null && lastPath.get('end_latitude') == null && creatingPath == null) {
      const creatingPath = {
        points: [],
        distance: 0
      };

      setCreatingPath(creatingPath);
      localStorage.creatingPath = JSON.stringify(creatingPath);
    }

  }, [lastPath])

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      if (typeof (Storage) !== "undefined") {
        getMacadams(props.selectedConstructionSite.get('id'));
      }

      socket.current.onmessage = async function (e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (data.model.construction_site) {
            if (props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
              return;
            }
          }
          else {
            const exist = props.macadams.find(mass => mass.paths_id === data.model.paths_id);
            if (!exist) {
              return;
            }
          }

          getMacadams(props.selectedConstructionSite.get('id'), true);
        }
        else if (data['operation'] === 'update') {
          if (data.model.construction_site) {
            if (props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
              return;
            }

            if (data.model.paths_id) {
              data.model.paths = await getPaths(data.model.paths_id);
            }

            props.changeMacadam(data.model);
          }
          else {
            const exist = props.macadams.find(mass => mass.paths_id === data.model.paths_id);
            if (exist) {
              getMacadams(props.selectedConstructionSite.get('id'), true);
            }
          }
        }
        else if (data['operation'] === 'delete') {
          props.removeMacadam(data.model);
        }
      }.bind(this);
    }

    if (props.selectedConstructionSite == null && viewState !== 0) {
      setViewState(0);
    }
  }, [props.selectedConstructionSite, viewState])

  useEffect(() => {
    if (autoGPS) {
      useLocation(true);
    }
  }, [autoGPS])

  useEffect(() => {
    getLastMacadamData(props.macadams, creatingMacadam);
  }, [creatingMacadam])

  useEffect(() => {
    getLastMacadamData(props.macadams, creatingMacadam);
    getDailyData(props.macadams);
    getMapPaths(props.macadams, props.selectedConstructionSite ?
      props.selectedConstructionSite.get('id') : null);
  }, [props.macadams])

  useEffect(() => {
    if (props.selectedContract == null || props.selectedConstructionSite == null) {
      props.clearMacadams();
    }
  }, [props.selectedContract, props.selectedConstructionSite])

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      getMacadams(props.selectedConstructionSite.get('id'));
    }
  }, [props.selectedConstructionSite])

  const changeState = (propertyName, type, defaultValue, event) => {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }

    if (propertyName === 'height') {
      setHeight(value);
    }
    if (propertyName === 'multiplier') {
      setMultiplier(value);
    }
    if (propertyName === 'variety') {
      setVariety(value);
    }
    if (propertyName === 'pit') {
      setPit(value);
    }
    if (propertyName === 'selectedSensorDevice') {
      setSelectedSensorDevice(value);
    }
    if (propertyName === 'road_part') {
      setRoad_part(value);
    }
    if (propertyName === 'road_distance') {
      setRoad_distance(value);
    }
    if (propertyName === 'autoGPS') {
      setAutoGPS(value);
    }
    if (propertyName === 'truckMass') {
      setTruckMass(value);
    }
    if (propertyName === 'attentions') {
      setAttentions(value);
    }
    if (propertyName === 'roadway') {
      setRoadway(value);
    }
    if (propertyName === 'lane') {
      setLane(value);
    }

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  const setLocation = async (position) => {
    let latitude1 = null;
    let longitude1 = null;
    let accuracy = null;
    let speed = null;

    if (position.coords) {
      if (usingSensorGPS) {
        return;
      }

      latitude1 = position.coords.latitude;
      longitude1 = position.coords.longitude;
      accuracy = Math.ceil(position.coords.accuracy);
      speed = position.coords.speed;
    }
    else {
      latitude1 = position.lat;
      longitude1 = position.lon;
      speed = position.speed;
    }

    if (latitude1 == null || longitude1 == null) {
      return;
    }

    const time = new Date();

    if (latitude != null && calculateDistance(
      latitude1, longitude1, latitude, longitude) >= 100) {
      resetKalmanFilter();
    }

    const kalmanCoordinates = kalmanFilter(latitude1, longitude1, accuracy, time.getTime(), speed);

    latitude1 = kalmanCoordinates.latitude;
    longitude1 = kalmanCoordinates.longitude;

    setLatitude(latitude1);
    setLongitude(longitude1);
    setAccuracy(accuracy)
    setLocationTime(paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds()));

    updatePath(latitude1, longitude1);

    if (REPTAIL) {
      if (sensor && deepnessSensor) {
        getDeepnessSensorValue();
      }
    }

    let road;

    if (props.selectedConstructionSite != null) {
      road = props.selectedConstructionSite.get('road_number');
    }

    const converted = toETRSTM35FIN(latitude1, longitude1);
    let data = await getRoadData(converted.y, converted.x, accuracy, road);

    if (data != null) {
      data = await getRoadData(converted.y, converted.x, accuracy);
    }

    if (data != null) {
      const distanceX = data.x - converted.x;
      const distanceY = data.y - converted.y;
      const allowDistance = 20;

      if (Math.sqrt(distanceX * distanceX + distanceY * distanceY) <= allowDistance) {
        setLocationErrorState(false);
        setLocationRoadNumber(data.road);
        setLocationRoadPart(data.part);
        setLocationRoadDistance(data.distance);
        return;
      }
    }

    setLocationErrorState(true);
    setLocationRoadNumber(null);
    setLocationRoadPart(null);
    setLocationRoadDistance(null);

    if (autoGPS) {
      setRoad_part(null);
      setRoad_distance(null);

      localStorage.road_part = null;
      localStorage.road_distance = null;
    }
  }

  useEffect(() => {
    if (locationErrorState === false) {
      useLocation(autoGPS);
    }
  }, [locationErrorState, locationRoadNumber, locationRoadPart, locationRoadDistance])

  const useLocation = async (autoGPS) => {
    if (autoGPS) {
      setRoad_part(locationRoadPart);
      setRoad_distance(locationRoadDistance);
    }

    const roadNumber = locationRoadNumber;

    if (props.selectedConstructionSite &&
      props.selectedConstructionSite.get('road_number') !== roadNumber) {
      return;
    }

    const creatingMacadam1 = creatingMacadam;

    if (creatingMacadam1 && roadNumber != null &&
      creatingMacadam1.roadParter != null &&
      locationRoadPart != null) {
      const distance = await calculateRoadDistance(roadNumber, creatingMacadam1.roadPart, creatingMacadam1.roadDistance,
        roadNumber, locationRoadPart, locationRoadDistance)
      setTraveledDistance(distance);
    }
  }

  useEffect(() => {
    if (showAccuracyFixer && accuracy < 20) {
      accuracySubmit();
    }

    localStorage.road_part = road_part;
    localStorage.road_distance = road_distance;
  }, [road_part, road_distance])

  const locationError = (err) => {
    props.showMessage('Virhe', 'ERROR(' + err.code + '): ' + err.message, 'Error');
  }

  const saveMacadam = (macadam, path) => {
    if (typeof (Storage) !== 'undefined') {
      if (localStorage['savedMacadams'] == null) {
        localStorage['savedMacadams'] = JSON.stringify([]);
      }

      macadam.id = Date.now();
      macadam.not_saved = true;
      macadam.paths = [path];

      let macadams = JSON.parse(localStorage['savedMacadams']);
      macadams.push(macadam);
      localStorage['savedMacadams'] = JSON.stringify(macadams);
    }
    else {
      props.showMessage('Virhe', 'Mursketta ei voitu tallentaa paikallisesti eikä palvelimelle', 'Error');
    }
  }

  const sendSavedMacadams = async () => {
    let macadams = JSON.parse(localStorage['savedMacadams']);
    let newMacadams = macadams.slice();
    let error = false;

    for (let index in macadams) {
      const macadam = macadams[index];

      try {
        const newMacadam = await fetch('/macadams/', 'POST', macadam);

        for (let i in macadam.paths) {
          let path = macadam.paths[i];
          path.paths_id = newMacadam.paths_id;

          try {
            await fetch('/path', 'POST', path);
          } catch (err) {

          }
        }

        newMacadams.splice(newMacadams.findIndex(m => m['id'] === macadam['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['savedMacadams'] = JSON.stringify(newMacadams);
    getMacadams(props.selectedConstructionSite.get('id'), error);
  }

  const sendUpdatedMacadams = async () => {
    let macadams = JSON.parse(localStorage['updatedMacadams']);
    let newMacadams = macadams.slice();
    let error = false;

    for (let index in macadams) {
      const macadam = macadams[index];

      try {
        const data = await fetch('/macadams/' + macadam.id, 'PATCH', macadam);

        for (let i in macadam.paths) {
          let path = macadam.paths[i];
          path.paths_id = data.paths_id;

          if (path.id) {
            await fetch('/path/' + path.id, 'PATCH', path);
          }
          else {
            await fetch('/path', 'POST', path);
          }
        }

        newMacadams.splice(newMacadams.findIndex(newMacadam => newMacadam['id'] === macadam['id']), 1);
      } catch (err) {
        console.log(err);
        error = true;
      }
    }

    localStorage['updatedMacadams'] = JSON.stringify(newMacadams);
    getMacadams(props.selectedConstructionSite.get('id'), error);
  }

  const confirmRoadInfo = (callback) => {
    setCallback(() => callback);
    setConfirmed(true);
  }

  useEffect(() => {
    if (callback != null) {
      callback();
      setCallback(null);
    }
  }, [confirmed])

  const saveNewPathToLastMacadam = (path) => {
    const lastMacadam = props.macadams.last();

    if (!lastMacadam.get('not_saved')) {
      if (localStorage['updatedMacadams'] == null) {
        localStorage['updatedMacadams'] = JSON.stringify([]);
      }

      let macadams = JSON.parse(localStorage['updatedMacadams']);
      const existingIndex = macadams.findIndex(macadam => macadam.id === lastMacadam.get('id'));

      if (existingIndex !== -1) {
        let paths = macadams[existingIndex].paths;
        paths.push(path);
      }
      else {
        let paths = [];

        lastMacadam.get('paths').forEach(path => {
          paths.push({
            id: path.get('id'),
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            lane: path.get('lane'),
            roadway: path.get('roadway'),
            direction: path.get('direction')
          });
        });

        paths.push(path);

        const macadam = {
          id: lastMacadam.get('id'),
          paths: paths
        }

        macadams.push(macadam);
      }

      localStorage['updatedMacadams'] = JSON.stringify(macadams);
    }
    else {
      let macadams = JSON.parse(localStorage['savedMacadams']);
      const index = macadams.findIndex(macadam => macadam.id === lastMacadam.get('id'));
      let paths = macadams[index].paths;
      paths.push(path);
      localStorage['savedMacadams'] = JSON.stringify(macadams);
    }

    props.addPathToLastMacadam(path);
  }

  const updateLocallyLastPath = async (path) => {
    let length;

    const endPart = path.end_part;
    const endDistance = path.end_distance;

    if (path.get_points) {
      if (endPart === lastPath.get('start_part')) {
        length = endDistance - lastPath.get('start_distance');
      }
      else {
        length = await calculateRoadDistance(lastPath.get('road'),
          lastPath.get('start_part'),
          lastPath.get('start_distance'),
          lastPath.get('road'),
          endPart, endDistance);
      }
    }

    const updatedPath = {
      id: lastPath.get('id'),
      road: lastPath.get('road'),
      start_part: lastPath.get('start_part'),
      start_distance: lastPath.get('start_distance'),
      end_part: endPart,
      end_distance: endDistance,
      length: length,
      lane: lastPath.get('lane'),
      roadway: lastPath.get('roadway'),
      direction: lastPath.get('direction'),
      date: lastPath.get('date'),
      end_latitude: path.end_latitude,
      end_longitude: path.end_longitude,
      get_points: path.get_points
    };

    const lastMacadam = props.macadams.last();

    if (!lastMacadam.get('not_saved')) {
      if (localStorage['updatedMacadams'] == null) {
        localStorage['updatedMacadams'] = JSON.stringify([]);
      }

      let macadams = JSON.parse(localStorage['updatedMacadams']);
      const existingIndex = macadams.findIndex(macadam => macadam.id === lastMacadam.get('id'));

      if (existingIndex !== -1) {
        let paths = macadams[existingIndex].paths;
        paths[paths.length - 1] = updatedPath;
      }
      else {
        let paths = [];

        lastMacadam.get('paths').forEach(path => {
          paths.push({
            id: path.get('id'),
            road: path.get('road'),
            start_part: path.get('start_part'),
            start_distance: path.get('start_distance'),
            end_part: path.get('end_part'),
            end_distance: path.get('end_distance'),
            length: path.get('length'),
            width: path.get('width'),
            area: path.get('area'),
            lane: path.get('lane'),
            roadway: path.get('roadway'),
            direction: path.get('direction'),
            location_on_road: path.get('location_on_road')
          });
        });

        paths[paths.length - 1] = updatedPath;

        const macadam = {
          id: lastMacadam.get('id'),
          paths: paths
        }

        macadams.push(macadam);
      }

      localStorage['updatedMacadams'] = JSON.stringify(macadams);
    }
    else {
      let macadams = JSON.parse(localStorage['savedMacadams']);
      const index = macadams.findIndex(macadam => macadam.id === lastMacadam.get('id'));
      let paths = macadams[index].paths;
      paths[paths.length - 1] = updatedPath;
      localStorage['savedMacadams'] = JSON.stringify(macadams);
    }

    props.updateLastPath(lastMacadam.get('id'), updatedPath);
  }

  const getMacadams = async (constructionSite, error = false) => {
    setLoadingMacadams(true);

    const allPaths = await fetch('/paths/macadam/site/' + constructionSite);

    if (!error && typeof (Storage) !== 'undefined') {
      if (localStorage['savedMacadams'] != null &&
        JSON.parse(localStorage['savedMacadams']).length !== 0) {
        await sendSavedMacadams();
        return;
      }
      if (localStorage['updatedMacadams'] != null &&
        JSON.parse(localStorage['updatedMacadams']).length !== 0) {
        await sendUpdatedMacadams();
        return;
      }
    }

    fetch('/macadams?site=' + constructionSite).then(async data => {
      for (let index in data) {
        let macadam = data[index];
        if (macadam.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === macadam.paths_id);
            macadam.paths = paths;
          } catch (error) {
            macadam.paths = [];
          }
        }
        else {
          macadam.paths = [];
        }
      }

      if (localStorage['savedMacadams'] != null && JSON.parse(localStorage['savedMacadams']).length !== 0) {
        const savedMacadams = JSON.parse(localStorage['savedMacadams']).filter(
          macadam => macadam.constructionSiteId === props.selectedConstructionSite.get('id'));
        data = data.concat(savedMacadams);
      }

      if (localStorage['updatedMacadams'] != null && JSON.parse(localStorage['updatedMacadams']).length !== 0) {
        const updatedMacadams = JSON.parse(localStorage['updatedMacadams']).filter(
          macadam => macadam.constructionSiteId === props.selectedConstructionSite.get('id'));
        updatedMacadams.forEach(macadam => {
          const index = data.findIndex(d => d.id === macadam.id);
          if (index !== -1) data.splice(index, 1);
          data.push(macadam);
        });
      }

      props.addMacadams(data);
    }).catch(error => {
      console.log(error);
      let macadams = [];

      if (localStorage['savedMacadams'] != null && JSON.parse(localStorage['savedMacadams']).length !== 0) {
        const savedMacadams = JSON.parse(localStorage['savedMacadams']).filter(
          macadam => macadam.constructionSiteId === props.selectedConstructionSite.get('id'));
        macadams = macadams.concat(savedMacadams);
      }

      if (localStorage['updatedMacadams'] != null && JSON.parse(localStorage['updatedMacadams']).length !== 0) {
        const updatedMacadams = JSON.parse(localStorage['updatedMacadams']).filter(
          macadam => macadam.constructionSiteId === props.selectedConstructionSite.get('id'));
        updatedMacadams.forEach(macadam => {
          const index = macadams.findIndex(d => d.id === macadam.id);
          if (index !== -1) macadams.splice(index, 1);
          macadams.push(macadam);
        });
      }

      props.addMacadams(macadams);
    })
      .then(() => {
        setLoadingMacadams(false);
      })
  }

  const goChangeMacadam = (macadamId) => {
    setChangingMacadam(macadamId);
  }

  const clearChangeMacadam = () => {
    setChangingMacadam(null);
  }

  const changeMacadam = (macadam) => {
    fetch('/macadams/' + macadam.get('id'), 'PATCH', macadam).then(async data => {
      props.showNotice('Murske muokattu', 'Ok');

      if (data.paths_id) {
        data.paths = macadam.get('paths');
      }

      props.changeMacadam(data);
      clearChangeMacadam();
    }).catch(error => {
      props.showMessage('Virhe', 'Murskeen muokkaus epäonnistui', 'Error');
    });
  }

  const changeLocalMacadam = (macadam) => {
    let macadams = JSON.parse(localStorage['savedMacadams']);
    const index = macadams.findIndex(macadam => macadam['id'] === changingMass.get('id'));
    macadams[index] = macadam;
    localStorage['savedMacadams'] = JSON.stringify(macadams);
    props.showNotice('Murske muokattu', 'Ok');
    props.changeMacadam(macadam);
    clearChangeMacadam();
  }

  const confirmRemoveMacadam = (macadam) => {
    removingMacadam = macadam;
    props.showConfirm('Poistetaanko murske?', removeMacadam);
  }

  const removeMacadam = () => {
    fetch('/macadams/' + removingMacadam + '/', 'DELETE').then(data => {
      props.showNotice('Murske poistettu', 'Ok')
      props.removeMacadam(removingMacadam);
    }).catch(error => {
      props.showMessage('Virhe', 'Murskeen poisto epäonnistui', 'Error');
    });
  }

  const removeLocalMacadam = () => {
    // Without timer does not work. Error: (Reducers may not dispatch actions.)
    timer(0).then(() => {
      let macadams = JSON.parse(localStorage['savedMacadams']);
      macadams = macadams.filter(macadam => macadam['id'] !== removingMacadam);
      localStorage['savedMacadams'] = JSON.stringify(macadams);
      props.showNotice('Murske poistettu', 'Ok')
      props.removeMacadam(removingMacadam);
    });
  }

  const confirmRemoveLocalMacadam = (macadamId) => {
    removingMacadam = macadamId;
    props.showConfirm('Poistetaanko murske?', removeLocalMacadam);
  }

  const confirmRemoveLastPath = (macadam) => {
    removingPathByMacadam = macadam;
    props.showConfirm('Poistetaanko viimeisin levitys kuormasta?', removeLastPath);
  }

  const removeLastPath = () => {
    const id = removingPathByMacadam.get('paths').last().get('id');

    fetch('/path/' + id, 'DELETE').then(data => {
      if (creatingPath.id === id) {
        clearCreatingPath();
      }

      props.showNotice('Levitys poistettu', 'Ok')
      props.removeLastPath(removingPathByMacadam.get('id'));
      setLastPath(props.macadams.last().get('paths').last());
    }).catch(error => {
      console.log(error);
      props.showMessage('Virhe', 'Levityksen poisto epäonnistui', 'Error');
    });
  }

  const getDailyData = (macadams) => {
    let dailyCount = 0;
    let dailyMass = 0;

    let wholeMass = 0;

    let now = new Date();
    now.setHours(now.getHours());

    let past12HoursFromNow = new Date(now);
    past12HoursFromNow.setHours(past12HoursFromNow.getHours() - 12);

    let newDay = false;

    for (let i = macadams.size - 1; i >= 0; i--) {
      const macadam = macadams.get(i);
      const date = new Date(macadam.get('date'));

      if (!newDay && date >= past12HoursFromNow &&
        date <= now) {
        dailyCount++;
        dailyMass += parseFloat(macadam.get('truck_mass'));
      }

      if (i !== 0) {
        let past6Hours = new Date(date);
        past6Hours.setHours(past6Hours.getHours() - 6);
        let beforeMacadamdate = new Date(macadams.get(i - 1).get('date'));
        beforeMacadamdate.setHours(beforeMacadamdate.getHours());
        if (past6Hours >= beforeMacadamdate) {
          newDay = true;
        }
      }

      wholeMass += parseFloat(macadam.get('truck_mass'));
    }

    setDailyCount(dailyCount);
    setDailyMass(dailyMass);
    setWholeMass(wholeMass);
  }

  const setView = (view) => {
    setViewState(view);
  }

  const goAddingPit = () => {
    const pit = prompt('Monttu:', '');
    if (pit == null || pit === '') return;
    else addPit(pit);;
  }

  const addPit = (pit) => {
    let pits1 = [];

    if (typeof (Storage) !== 'undefined') {
      if (localStorage['pits'] == null) {
        localStorage['pits'] = JSON.stringify([]);
      }
      pits1 = JSON.parse(localStorage['pits']);

      if (pits1.length === 3) {
        pits1.splice(2, 1);
      }

      pits1.splice(0, 0, pit);
      localStorage['pits'] = JSON.stringify(pits1);
    }
    else {
      pits1 = pits;
      pits1.push(pit);
    }

    setPits(pits1);
  }

  const toggleAccuracyFixer = (submit) => {
    setAccuracySubmit(submit);
    setShowAccuracyFixer(!showAccuracyFixer);
  }

  const getLastMacadamData = (macadams, creatingMacadam) => {
    let lastPart = 0;
    let lastPole = 0;
    let lastHeight = null;
    let lastMultiplier = null;
    let lastVariety = null;
    let lastPit = null;
    let lastDirection = null;
    let lastRoadway = null;
    let lastLane = null;
    let lastPath = null;

    const lastMacadam = macadams.last();

    if (creatingMacadam) {
      lastPart = creatingMacadam.roadPart;
      lastPole = creatingMacadam.roadDistance;
    }
    else if (lastMacadam != null) {
      if (lastMacadam.get('paths').size !== 0) {
        const path = lastMacadam.get('paths').last();

        if (path.get('end_part')) {
          lastPart = path.get('end_part');
          lastPole = path.get('end_distance');
        }
        else {
          lastPart = path.get('start_part')
          lastPole = path.get('start_distance');
        }

        lastPath = path;
      }

      lastHeight = lastMacadam.get('height');
      lastMultiplier = lastMacadam.get('multiplier');
      lastVariety = lastMacadam.get('variety');
      lastPit = lastMacadam.get('pit');
      lastDirection = lastPath ? lastPath.get('direction') : null;
      lastRoadway = lastPath ? lastPath.get('roadway') : null;
      lastLane = lastPath ? lastPath.get('lane') : null;
    }

    setLastPart(lastPart);
    setLastPole(lastPole);
    setLastHeight(lastHeight);
    setLastMultiplier(lastMultiplier);
    setLastVariety(lastVariety);
    setLastPit(lastPit);
    setLastDirection(lastDirection);
    setLastRoadway(lastRoadway);
    setLastLane(lastLane);
    setLastPath(lastPath);
  }

  const getMapPaths = async (macadams, site) => {
    setMapPaths([])

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/macadam/site/' + site);
      } catch (err) { }
    }

    for (let i = 0; i < macadams.size; i++) {
      const mass = macadams.get(i);

      for (let p = 0; p < mass.get('paths').size; p++) {
        const path = mass.get('paths').get(p);

        if (!path.get('start_latitude')) continue;

        const startLatitude = toRadians(path.get('start_latitude'));
        const startLongitude = toRadians(path.get('start_longitude'));
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.get('end_latitude')) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.get('id'));

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              positions.push([point.latitude, point.longitude])
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.get('end_distance')) {
              positions.push([path.get('end_latitude'), path.get('end_longitude')])
            }
          }
          else {
            positions = [[path.get('start_latitude'), path.get('start_longitude')],
            [path.get('end_latitude'), path.get('end_longitude')]];
          }

          const endLatitude = toRadians(path.get('end_latitude'));
          const endLongitude = toRadians(path.get('end_longitude'));
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.get('start_latitude'), path.get('start_longitude')],
          [path.get('start_latitude'), path.get('start_longitude')]]
        }

        if (path.get('direction') === 2) {
          if (path.get('start_part') > path.get('end_part') ||
            path.get('start_distance') > path.get('end_distance')) {
            positions.reverse();
          }
          positions = await get2DirectionPath(positions);
        }

        paths[path.get('id')] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else if (props.yourLatitude != null) {
      zoom = 15;
      position = [props.yourLatitude, props.yourLongitude];
    }

    setMapPaths(paths);
    setMapZoom(zoom);
    setMapPosition(position);
  }

  const get2DirectionPath = async (path) => {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
      try {
        const converted = toETRSTM35FIN(path[0][0], path[0][1]);
        let roadData = await getRoadData(converted.y, converted.x, 10);
        const roadNumber = roadData.road;
        const roadPart = roadData.part;
        let roadDistance = roadData.distance;
        const anotherPointDistance = 10;

        if (roadDistance < anotherPointDistance) {
          roadDistance = roadDistance + anotherPointDistance;
        }
        else {
          roadDistance = roadDistance - anotherPointDistance;
        }

        let coordinates = await getRoadCoordinates(roadNumber, roadPart, roadDistance);

        const anotherCoordinates = toWGS84(coordinates.y, coordinates.x);

        const angle = getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
          path[0][0], path[0][1]);

        const newCoordinate = getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
        newPath = [newCoordinate, newCoordinate]
      } catch (error) {
        return path;
      }
    }

    return newPath;
  }

  const getOffSetAngle = (lat1, lon1, lat2, lon2) => {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
      Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  const getNewCoordinatesByAngle = (angle, latitude, longitude) => {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
      Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  const getPaths = async (id) => {
    const paths = await fetch('/paths/' + id);
    return paths;
  }

  const clearCreatingPath = () => {
    setCreatingPath(null);
    localStorage.removeItem('creatingPath');
  }

  const startMacadam = () => {
    if (props.selectedConstructionSite == null) {
      props.showNotice('Kohdetta ei ole valittu', 'Warning')
      return;
    }

    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() - timezoneOffset);

    const macadam = {
      constructionSiteId: props.selectedConstructionSite.get('id'),
      date: date.toISOString().replace('Z', ''),
      latitude: latitude,
      longitude: longitude
    };

    const points = [{
      latitude: latitude,
      longitude: longitude
    }];

    let path = {
      distance: 0,
      points: points
    };

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart = road_part;
    const roadDistance = road_distance;

    if (roadNumber != null &&
      roadPart != null &&
      roadDistance != null) {
      path.roadNumber = roadNumber;
      path.roadPart = roadPart;
      path.roadDistance = roadDistance;
    }

    setCreatingMacadam(macadam);
    setCreatingPath(path);

    localStorage['creatingMacadam'] = JSON.stringify(macadam);
    localStorage['creatingPath'] = JSON.stringify(path);

    props.showNotice('Kuorma aloitettu', 'Ok');
  }

  const endMacadam = async () => {
    const currentMacadam = creatingMacadam;

    setDisableSubmit(true);

    if (currentMacadam != null) {
      const macadam = {
        constructionSiteId: props.selectedConstructionSite.get('id'),
        height: height ? parseFloat(height) : null,
        multiplier: height ? parseFloat(multiplier) : null,
        variety: variety,
        pit: pit,
        truck_mass: height ? parseFloat(truckMass) : null,
        truckId: props.selectedTruck ? props.selectedTruck.get('id') : null,
        truck: props.selectedTruck ? { register_number: props.selectedTruck.get('register_number') } : null,
        attentions: attentions,
        date: currentMacadam.date
      };

      const currentPath = creatingPath;

      const points = currentPath.points;

      let path = {
        start_latitude: points[0].latitude,
        start_longitude: points[0].longitude,
        end_latitude: points[points.length - 1].latitude,
        end_longitude: points[points.length - 1].longitude,
        roadway: roadway,
        lane: lane,
        direction: direction,
        date: currentMacadam.date
      };

      if (currentPath.roadNumber != null &&
        currentPath.roadPart != null &&
        currentPath.roadDistance != null &&
        road_part != null &&
        road_distance != null) {
        path.road = currentPath.roadNumber;
        path.start_part = currentPath.roadPart;
        path.start_distance = currentPath.roadDistance;
        path.end_part = road_part;
        path.end_distance = road_distance;
        path.get_points = true;
      }
      else {
        path.get_points = false;
        path.length = Math.round(currentPath.distance * 10) / 10;
      }

      let newMacadam;

      let date = new Date();
      const timezoneOffset = date.getTimezoneOffset() / 60;
      date.setHours(date.getHours() - timezoneOffset);

      try {
        newMacadam = await fetch('/macadams', 'POST', macadam);
        newMacadam.endDate = date.toISOString().replace('Z', '');
      } catch (error) {
        saveMacadam(macadam, path);
        macadam.date = macadam.date + 'Z';
      }

      if (newMacadam != null) {
        path.paths_id = newMacadam.paths_id;

        try {
          path = await fetch('/path', 'POST', path);

          if (!path.get_points) {
            for (let index in points) {
              let point = points[index];
              point.path_id = path.id;

              try {
                await fetch('/points', 'POST', point);
              } catch (error) {

              }
            }
          }
        } catch (error) {
          console.log(error)
        }
      }

      props.showNotice('Kuorma lopetettu', 'Ok');
      props.selectTruck(null);

      setTruckMass(0);
      setAttentions("");

      localStorage.removeItem("truck");
      localStorage.removeItem("truckMass");
      localStorage.removeItem("attentions");
      localStorage.removeItem('creatingMacadam');
      localStorage.removeItem('creatingPath');

      try {
        await getMacadams(props.selectedConstructionSite.get('id'), true);
      } catch (err) {
        macadam.paths = [path];
        props.addMacadam(macadam);
      }

      await sendSensorsData(newMacadam);

      setCreatingMacadam(null),
        setCreatingPath(null);
    }
    else {
      await makeEndForPath();
    }

    setDisableSubmit(false);
  }

  const newPath = async () => {
    if (props.selectedConstructionSite == null) {
      props.showNotice('Kohdetta ei ole valittu', 'Warning');
      return;
    }

    if (lastPath == null) {
      props.showNotice('Aikasempaa kuormaa ei ole', 'Warning');
      return;
    }

    let date = new Date();
    date.setHours(date.getHours());

    let path = {
      paths_id: lastPath.get('paths_id'),
      roadway: roadway,
      lane: lane,
      direction: direction,
      date: date.toISOString().replace('Z', ''),
      start_latitude: latitude,
      start_longitude: longitude
    };

    const roadNumber = props.selectedConstructionSite.get('road_number');
    const roadPart = road_part;
    const roadDistance = road_distance;

    if (roadNumber != null &&
      roadPart != null &&
      roadDistance != null) {
      path.road = roadNumber;
      path.start_part = roadPart;
      path.start_distance = roadDistance;
    }

    fetch('/path', 'POST', path).then(async data => {
      if (!lastPath.get('end_latitude')) {
        await makeEndForPath();
      }

      props.showNotice('Uusi aloitus tehty', 'Ok');
      props.addPathToLastMacadam(data);
    }).catch(error => {
      saveNewPathToLastMacadam(path);
      props.showNotice('Uusi aloitus tallenettu paikallisesti', 'Warning');
    }).then(() => {
      const points = [{
        latitude: latitude,
        longitude: longitude
      }];

      const creatingPath = {
        start_part: path.start_part,
        start_distance: path.start_distance,
        distance: 0,
        points: points
      };

      setCreatingPath(creatingPath);

      localStorage['creatingPath'] = JSON.stringify(creatingPath);

      setDisableNewPath(false);
    });
  }

  const makeEndForPath = async () => {
    let path = {};

    if (lastPath.get('road') != null &&
      lastPath.get('start_part') != null &&
      lastPath.get('start_distance') != null &&
      road_part != null &&
      road_distance != null) {
      path.end_part = road_part;
      path.end_distance = road_distance;
      path.get_points = true;
    }
    else {
      path.get_points = false;
      path.length = Math.round(creatingPath.distance * 10) / 10;
    }

    path.end_latitude = latitude;
    path.end_longitude = longitude;

    const lastMacadam = props.macadams.last();

    if (!lastMacadam.get('not_saved')) {
      try {
        const data = await fetch('/path/' + lastPath.get('id'), 'PATCH', path);

        if (!path.get_points) {
          const points = creatingPath.points;

          for (let index in points) {
            let point = points[index];
            point.path_id = data.id;

            try {
              await fetch('/points', 'POST', point);
            } catch (error) {

            }
          }
        }

        props.showNotice('Lopetus paalu tehty', 'Ok');
        props.updateLastPath(lastMacadam.get('id'), data);
      } catch (error) {
        updateLocallyLastPath(path);
        props.showNotice('Lopetus paalu tallennettu paikallisesti', 'Warning');
      }
    }
    else {
      updateLocallyLastPath(path);
      props.showNotice('Lopetus paalu tehty', 'Ok');
    }

    clearCreatingPath();

    await sendSensorsData(lastMacadam);
  }

  const updatePath = (latitude, longitude) => {
    let creatingPath1 = creatingPath;

    if (creatingPath1 == null) {
      return;
    }

    let points = creatingPath1.points;

    if (points.length > 0) {
      const lastPoint = points[points.length - 1];
      const distance = calculateDistance(latitude, longitude,
        lastPoint.latitude, lastPoint.longitude);

      if (distance < 1) {
        return;
      }

      creatingPath1.distance += distance;
    }

    points.push({
      latitude: latitude,
      longitude: longitude
    });

    localStorage.creatingPath = JSON.stringify(creatingPath1);

    setCreatingPath(creatingPath1);
    setTraveledDistance(Math.round(creatingPath.distance * 10) / 10);
  }

  const toggleSensors = () => {
    const SENSOR_INTERVAL = 5000;
    const value = !sensors;

    if (value) {
      updateSensorValues();
      sensorTimer = setInterval(updateSensorValues, SENSOR_INTERVAL);
    }
    else {
      clearInterval(sensorTimer);
    }

    setSensors(value);
    localStorage.sensors = value;
  }

  const jsonToObject = (csv) => {
    let arr = csv.replace('\r', '').split('\n');
    let jsonObj = [];
    let headers = arr[0].split(',');


    for (let i = 1; i < arr.length; i++) {
      let data = arr[i].split(',');
      let obj = {};

      for (let j = 0; j < data.length; j++) {
        if (j === headers.length) {
          break;
        }

        obj[headers[j].trim()] = data[j].trim();
      }

      jsonObj.push(obj);
    }

    return jsonObj;
  }

  const getSensorValues = async (startTime = null, endTime = null) => {
    if (selectedSensorDevice === '') {
      if (sensorDevices.length === 0) {
        getSensorDevices();
      }

      return null;
    }

    let csv;

    try {
      if (startTime != null) {
        const startTimeString = startTime.toISOString()
        const endTimeString = endTime.toISOString()
        csv = await fetchSensorData(startTimeString, selectedSensorDevice, endTimeString);
      }
      else {
        csv = await fetchSensorData(SENSOR_TIME_AGO, selectedSensorDevice);
      }
    } catch (error) {

    }

    if (csv == null) return null;

    const jsonObj = jsonToObject(csv);

    let angles = [];
    let widths = [];
    let leftPots = [];
    let rightPots = [];
    let latitudes = [];
    let longitudes = [];

    angles = jsonObj.filter(field => field['_field'] === 'Angle');
    widths = jsonObj.filter(field => field['_field'] === 'WorkWidth');
    leftPots = jsonObj.filter(field => field['_field'] === 'LeftPot');
    rightPots = jsonObj.filter(field => field['_field'] === 'RightPot');
    latitudes = jsonObj.filter(field => field['_field'] === 'lat');
    longitudes = jsonObj.filter(field => field['_field'] === 'lon');

    return {
      angles: angles,
      widths: widths,
      leftPots: leftPots,
      rightPots: rightPots,
      latitudes: latitudes,
      longitudes: longitudes
    }
  }

  const updateSensorValues = async () => {
    const values = await getSensorValues();

    if (values == null) {
      return;
    }

    const angles = values.angles;
    const widths = values.widths;
    const leftPots = values.leftPots;
    const rightPots = values.rightPots;
    const latitudes = values.latitudes;
    const longitudes = values.longitudes;

    if (latitudes.length !== 0) {
      const latestLatitude = latitudes[latitudes.length - 1];
      const latestLongitude = longitudes[longitudes.length - 1];

      if (!usingSensorGPS) {
        setUsingSensorGPS(true);
      }
      else {
        const latestLocationTime = new Date(latestLatitude['_time']);
        const currentTime = new Date();

        if (currentTime > latestLocationTime + 60) {
          setUsingSensorGPS(false);
        }
      }

      const latestLatitudeValue = parseFloat(latestLatitude['_value']);
      const latestLongitudeValue = parseFloat(latestLongitude['_value']);
      const position = { lat: latestLatitudeValue, lon: latestLongitudeValue };
      setLocation(position);
    }

    if (angles.length === 0 || (latestAngles.length !== 0 &&
      latestAngles[latestAngles.length - 1]['_time'] === angles[angles.length - 1]['_time'])) {
      return;
    }

    const currentAngle = angles[angles.length - 1];
    const currentWidth = widths[widths.length - 1];
    const currentLeftPotHeight = leftPots[leftPots.length - 1];
    const currentRightPotHeight = rightPots[rightPots.length - 1];

    let newestTime = currentAngle['_time'];

    if (currentWidth != null && newestTime < currentWidth['_time']) {
      newestTime = currentWidth['_time'];
    }

    if (currentLeftPotHeight != null && newestTime < currentLeftPotHeight['_time']) {
      newestTime = currentLeftPotHeight['_time'];
    }

    if (currentRightPotHeight != null && newestTime < currentRightPotHeight['_time']) {
      newestTime = currentRightPotHeight['_time'];
    }

    let latestAngles1 = latestAngles;
    let latestWidths1 = latestWidths;
    let latestLeftPotHeights1 = latestLeftPotHeights;
    let latestRightPotHeights1 = latestRightPotHeights;

    if (latestAngles1.length === 10) {
      latestAngles1.splice(1, 1);
    }
    if (latestWidths1.length === 10) {
      latestWidths1.splice(1, 1);
    }
    if (latestLeftPotHeights1.length === 10) {
      latestLeftPotHeights1.splice(1, 1);
    }
    if (latestRightPotHeights1.length === 10) {
      latestRightPotHeights1.splice(1, 1);
    }

    if (currentAngle != null) {
      latestAngles1.push(currentAngle);
    }
    if (currentWidth != null) {
      latestWidths1.push(currentWidth);
    }
    if (currentLeftPotHeight != null) {
      latestLeftPotHeights1.push(currentLeftPotHeight);
    }
    if (currentRightPotHeight != null) {
      latestRightPotHeights1.push(currentRightPotHeight);
    }

    setLatestAngles(latestAngles1);
    setLatestWidths(latestWidths1);
    setLatestLeftPotHeights(latestLeftPotHeights1);
    setLatestRightPotHeights(latestRightPotHeights1);
    setLastSensorTime(new Date(newestTime));

    if (creatingMacadam == null) {
      return;
    }
  }

  const sendSensorsData = async (macadam) => {
    const creatingMacadamTime = new Date(macadam.date);
    const endMacadamTime = new Date(macadam.endDate);

    const values = await getSensorValues(creatingMacadamTime, endMacadamTime);

    if (values == null) {
      return;
    }

    let angles = values.angles;
    let widths = values.widths;
    let leftPots = values.leftPots;
    let rightPots = values.rightPots;
    let latitudes = values.latitudes;
    let longitudes = values.longitudes;

    angles = angles.filter(field => {
      const time = new Date(field['_time']);
      return (time >= creatingMacadamTime && time <= endMacadamTime);
    });

    widths = widths.filter(field => {
      const time = new Date(field['_time']);
      return (time >= creatingMacadamTime && time <= endMacadamTime);
    });

    leftPots = leftPots.filter(field => {
      const time = new Date(field['_time']);
      return (time >= creatingMacadamTime && time <= endMacadamTime);
    });

    rightPots = rightPots.filter(field => {
      const time = new Date(field['_time']);
      return (time >= creatingMacadamTime && time <= endMacadamTime);
    });

    if (angles.length === 0) {
      return;
    }

    for (let index = 0; angles.length > index; index++) {
      const angle = angles[index];
      const angleTime = new Date(angle['_time']);
      let locationIndex;

      for (let latIndex in latitudes) {
        const latitude = latitudes[latIndex];
        const latitudeTime = new Date(latitude['_time']);

        if (angleTime < latitudeTime) {
          break;
        }

        locationIndex = latIndex;
      }

      if (locationIndex == null) {
        angles.splice(index, 1);
        widths.splice(index, 1);
        leftPots.splice(index, 1);
        rightPots.splice(index, 1);
        index--;
        continue;
      }

      const latitude = parseFloat(latitudes[locationIndex]['_value']);
      const longitude = parseFloat(longitudes[locationIndex]['_value']);
      const coordinates = { latitude: latitude, longitude: longitude };

      angles[index]['location'] = coordinates;
      widths[index]['location'] = coordinates;
      leftPots[index]['location'] = coordinates;
      rightPots[index]['location'] = coordinates;
    }

    let lastLatitude = angles[0]['location']['latitude'];
    let lastLongitude = angles[0]['location']['longitude'];

    for (let index = 1; angles.length > index; index++) {
      const latitude = angles[index]['location']['latitude'];
      const longitude = angles[index]['location']['longitude'];

      const distanceBetween = calculateDistance(latitude, longitude, lastLatitude, lastLongitude);

      if (distanceBetween >= 1) {
        const converted = toETRSTM35FIN(latitude, longitude);
        const data = await getRoadData(converted.y, converted.x);

        const roadNumber = data.road;
        const roadPart = data.part;
        const roadDistance = data.distance;

        angles[index]['location']['roadNumber'] = roadNumber;
        angles[index]['location']['roadPart'] = roadPart;
        angles[index]['location']['roadDistance'] = roadDistance;

        widths[index]['location']['roadNumber'] = roadNumber;
        widths[index]['location']['roadPart'] = roadPart;
        widths[index]['location']['roadDistance'] = roadDistance;

        leftPots[index]['location']['roadNumber'] = roadNumber;
        leftPots[index]['location']['roadPart'] = roadPart;
        leftPots[index]['location']['roadDistance'] = roadDistance;

        rightPots[index]['location']['roadNumber'] = roadNumber;
        rightPots[index]['location']['roadPart'] = roadPart;
        rightPots[index]['location']['roadDistance'] = roadDistance;

        lastLatitude = latitude;
        lastLongitude = longitude;
      }
      else {
        angles.splice(index, 1);
        widths.splice(index, 1);
        leftPots.splice(index, 1);
        rightPots.splice(index, 1);
        index--;
      }
    }

    for (let index in angles) {
      let date = new Date(angles[index]['_time']);
      const timezoneOffset = date.getTimezoneOffset() / 60;
      date.setHours(date.getHours() - timezoneOffset);

      let data = {
        macadam_id: macadam.id,
        time: date.toISOString().replace('Z', ''),
        latitude: angles[index]['location']['latitude'],
        longitude: angles[index]['location']['longitude'],
        road_number: angles[index]['location']['roadNumber'],
        road_part: angles[index]['location']['roadPart'],
        road_distance: angles[index]['location']['roadDistance']
      };

      try {
        data.angle = parseFloat(angles[index]['_value'], 10);
        await fetch('/angle', 'POST', data);

        data.width = parseFloat(widths[index]['_value'], 10);
        await fetch('/width', 'POST', data);

        data.height = parseFloat(leftPots[index]['_value'], 10);
        data.location = 0;
        await fetch('/pot', 'POST', data);

        data.height = parseFloat(rightPots[index]['_value'], 10);
        data.location = 1;
        await fetch('/pot', 'POST', data);
      } catch (error) {
      }
    }
  }

  const getSensorDevices = async () => {
    let csv;

    try {
      csv = await fetchSensorData(SENSOR_TIME_AGO);
    } catch (error) {
      setSelectedSensorDevice('');
    }

    if (csv == null) return;

    const jsonObj = jsonToObject(csv);

    const devices = jsonObj.filter(field => field['device'] != null);

    let existSelectedDevice = false;

    let deviceNames = [];

    for (let index in devices) {
      const name = devices[index]['device'];
      deviceNames[index] = name;

      if (name === localStorage.selectedSensorDevice) {
        existSelectedDevice = true;
      }
    }

    setSensorDevices(deviceNames);
    setSelectedSensorDevice(existSelectedDevice ? localStorage.selectedSensorDevice : '');
  }

  return (
    <div>
      <div className="container">
        <div className='button-area'>
          <button className='button-view' onClick={setView.bind(this, 0)}
            disabled={viewState === 0}>
            Perustiedot
          </button>
          <button className='button-view' onClick={setView.bind(this, 1)}
            disabled={viewState === 1}>
            Levitystiedot
          </button>
          <button className='button-view' onClick={setView.bind(this, 2)}
            disabled={viewState === 2}>
            Seurantatiedot
          </button>
          <button className='button-view' onClick={setView.bind(this, 4)}
            disabled={viewState === 4}>
            Sensorit
          </button>
          <button className='button-view' onClick={setView.bind(this, 3)}
            disabled={viewState === 3}>
            Kartta
          </button>
        </div>
        <View view={viewState} changeState={changeState} height={height}
          multiplier={multiplier} roadway={roadway} direction={direction}
          lane={lane} startRoadPart={road_part}
          startRoadDistance={road_distance}
          latitude={Math.round(latitude * 10000) / 10000}
          longitude={Math.round(longitude * 10000) / 10000}
          road={props.selectedConstructionSite ? props.selectedConstructionSite.get('road_number') : '-'}
          newMacadam={startMacadam} useLocation={useLocation}
          mass={truckMass}
          attentions={attentions}
          dailyMass={dailyMass}
          dailyCount={dailyCount} wholeMass={wholeMass}
          macadams={props.macadams} selectedConstructionSite={props.selectedConstructionSite}
          loading={loadingMacadams} confirmRemoveMacadam={confirmRemoveMacadam}
          confirmRemoveLocalMacadam={confirmRemoveLocalMacadam}
          removeLastPath={confirmRemoveLastPath}
          locationRoadNumber={locationRoadNumber}
          locationRoadPart={locationRoadPart}
          locationRoadDistance={locationRoadDistance}
          accuracy={accuracy}
          locationTime={locationTime}
          mapPaths={mapPaths} mapZoom={mapZoom} mapPosition={mapPosition}
          showMessage={props.showMessage} changeMacadam={goChangeMacadam}
          variety={variety} pit={pit} pits={pits}
          goAddingPit={goAddingPit} autoGPS={autoGPS}
          endMacadam={endMacadam}
          creatingMacadam={props.selectedConstructionSite && ((creatingMacadam &&
            creatingMacadam.constructionSiteId === props.selectedConstructionSite.get('id'))
            || (lastPath && lastPath.get('end_latitude') == null))}
          disableSubmit={disableSubmit}

          lastPart={lastPart}
          lastPole={lastPole}
          lastHeight={lastHeight}
          lastMultiplier={lastMultiplier}
          lastVariety={lastVariety}
          lastPit={lastPit}
          lastDirection={lastDirection}
          lastRoadway={lastRoadway}
          lastLane={lastLane}
          lastLatitude={lastLatitude}
          lastLongitude={lastLongitude}

          paths={paths}
          newPath={newPath}
          disableNewPath={disableNewPath}
          organizationId={props.organizationId}
          latestAngles={latestAngles}
          latestWidths={latestWidths}
          latestLeftPotHeights={latestLeftPotHeights}
          latestRightPotHeights={latestRightPotHeights}

          sensorDevices={sensorDevices}
          selectedSensorDevice={selectedSensorDevice}
          lastSensorTime={lastSensorTime}
          lastSensorLatitude={lastSensorLatitude}
          lastSensorLongitude={lastSensorLongitude}
          store={props.store} />
        <ChangeMacadamView macadam={changingMacadam} clear={clearChangeMacadam}
          changeMacadam={changeMacadam} changeLocalMacadam={changeLocalMacadam} />
        <AccuracyFixer show={showAccuracyFixer} toggle={toggleAccuracyFixer}
          submit={!creatingMacadam ? startMacadam : endMacadam} accuracy={accuracy} />
        <div id='top-info'>
          {creatingPath ?
            <div>
              {traveledDistance != null ?
                <div>
                  <span className='macadam-distance-info'>
                    <strong>
                      Matka:
                    </strong>
                    {' ' + traveledDistance} m
                  </span>
                </div>
                : null
              }
            </div>
            :
            null
          }
          <span className='macadam-distance-info'>
            <strong>
              Korkeus:
            </strong>
            {' ' + height} mm
          </span>
          <span className='macadam-distance-info'>
            <strong>
              Kerroin:
            </strong>
            {' ' + multiplier}
          </span>
        </div>
      </div>
    </div>
  );
}

export default connect(state => ({
  macadams: state.macadam.get('macadams'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), {
  addMacadam, addMacadams, removeMacadam, showNotice, showConfirm,
  clearMacadams, selectTruck, showMessage, changeMacadam, updateLastPath,
  addPathToLastMacadam, removeLastPath
})(MacadamNew);
